import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Nav = ({ siteTitle }) => (
  <nav>
    <div className="pt-4 pb-3 text-center bg-gray-100 md:pt-4">
      <h1 className="text-2xl italic md:text-4xl font-baskerville mb-3">
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
      <ul className="text-sm md:text-lg font-baskerville">
        <a href="https://waze.com/ul/h9fwus50tk" className="px-4 md:px-6">Ver ubicación</a>
        <a href="https://wa.link/o2zqq9" className="px-4 md:px-6">Confirmar Asistencia</a>
      </ul>
    </div>
  </nav>
)

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav